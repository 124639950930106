import { BasicUtils } from '~/utilities/basic/utils';
interface IPromoScene {
  promoSceneCode: string,
  subPromoSceneCode: string,
  cieFlag: number,
  limitFlag: number
}
interface IPromoCardRequest {
  promoSceneList: Array<IPromoScene>,
}
export class Utils extends BasicUtils {
  async getPromoCardList(params: IPromoCardRequest): Promise<any> {
    try {
      const data = await this.axios.post(
        `${this.host}/o2pmt/v1/pub/promocode/recommend-enhance`, {
          promoSceneList: params
        });
      return data;
    } catch (e) {
      return { promoSceneList: [{ promoCodeList: [] }] };
    }
  }
}

export const sortCouponData = (couponDatas: Array<any>, popupFlag: Boolean) => {
  let couponData = couponDatas.filter((item) => {
    return item.statusCode === 'TO_BE_RECEIVED';
  });
  const unUsedCoupon = couponDatas.filter(item=>{
    return item.statusCode === 'UNUSED';
  });
  const publicCoupon: any[] = [];
  if (unUsedCoupon.length) {
    unUsedCoupon.forEach(unUsed=>{
      couponData.forEach(toReceive=>{
        if (toReceive.promoCodeId === unUsed.promoCodeId) {
          publicCoupon.push(toReceive);
        }
      });
    });
  }
  // 这里如果是弹窗则筛选去除掉既是待领取又有未使用状态的优惠券
  couponData = popupFlag? couponData.filter(item=>!publicCoupon.includes(item)):couponData;
  couponData.forEach((item) => {
    if (item.reduction) {
      item.sort = 1;
    } else {
      item.sort = 2;
    }
  });
  couponData.sort((a, b) => {
    return a.sort - b.sort;
  });
  couponData.sort((a, b) => {
    return b.reduction - a.reduction || a.discount - b.discount;
  });
  return couponData;
};
// 这里找到时间最近的且已开始的配置
export const findConfig = (config: Array<any>) =>{
  const allConfig = config?.filter(item=>new Date(item.startTime) <= new Date());
  config = allConfig?.sort((a, b)=>{
    return new Date(b.startTime).getTime() - new Date(a.startTime).getTime();
  });
  return config?config[0] : [];
};

// 等待图片加载完成
export function loadImg(url:string){
  return new Promise<void>((resolve,reject)=>{
    const img=new Image;
    function endLoad(){
      img.onload =  img.onerror=null;
    };
    img.onload = function (e) {
      endLoad();
      resolve();
    };
    img.onerror = function (e) {
      endLoad();
      reject( new Error('图片加载失败'));
    };
    img.src=url;
    if(img.complete){
      endLoad();
      resolve();
    }
    setTimeout(() => {
      endLoad();
      reject( new Error('图片加载失败'));
    }, 5000);
  });
}
