

export default {
  props: {
    time: {
      type: [Number, String],
      default: 0
    },
    refreshCounter: {
      type: [Number, String],
      default: 0
    },
    end: {
      type: [Number, String],
      default: 0
    },
    isMiniSecond: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'white'
    },
    leftClass: {
      type: String,
      default: ''
    },
    rightClass: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    days: '0',
    hours: '00',
    mins: '00',
    seconds: '00',
    timer: null,
    isIos:false,
    curTime: 0
  }),
  computed: {
    duration() {
      if (this.end) {
        let end = String(this.end).length >= 13 ? +this.end : +this.end * 1000;
        end -= Date.now();
        return end;
      }
      const time = this.isMiniSecond ? Math.round(+this.time / 1000) : Math.round(+this.time);
      return time;
    }
  },
  watch: {
    duration() {
      this.countDown();
    },
    refreshCounter() {
      this.countDown();
    }
  },
  mounted() {
    const u = navigator.userAgent.toLowerCase();
    this.isIos = !!u.match(/macintosh|mac os x/i);
    this.countDown();
  },
  methods: {
    durationFormatter(time) {
      if (!time) return { ss: 0 };
      let t = time;
      const ss = t % 60;
      t = (t - ss) / 60;
      if (t < 1) return { ss };
      const mm = t % 60;
      t = (t - mm) / 60;
      if (t < 1) return { mm, ss };
      const hh = t;
      // const hh = t % 24;
      // t = (t - hh) / 24;
      // if (t < 1)
      return { hh, mm, ss };
      // const dd = t;
      // return { dd, hh, mm, ss };
    },
    countDown() {
      // eslint-disable-next-line no-unused-expressions
      this.curTime = Date.now();
      this.getTime(this.duration);
    },
    getTime(time) {
      // eslint-disable-next-line no-unused-expressions
      this.timer && clearTimeout(this.timer);
      if (time < 0) {
        return;
      }
      // eslint-disable-next-line object-curly-newline
      const { dd, hh, mm, ss } = this.durationFormatter(time);
      this.days = dd || 0;
      this.hours = hh || 0;
      this.mins = mm || 0;
      this.seconds = ss || 0;
      this.timer = setTimeout(() => {
        const now = Date.now();
        const diffTime = Math.floor((now - this.curTime) / 1000);
        // 页面退到后台的时候不会计时，对比时间差，大于1s的重置倒计时
        const step = diffTime > 1 ? diffTime : 1;
        this.curTime = now;
        this.getTime(time - step);
      }, 1000);
    }
  }
};
