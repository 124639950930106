
import { LibButton, LibMask } from '@adidas-com-front/ui-library';
import { mapState } from 'vuex';
import { DayJs, LibFormatter } from '@adidas-com-front/ui-library/src/components/atoms/LibFormat/LibFormat.utils';
import * as myAccountTrack from '../../../pages/index/track';
import {couponPopupView,couponPopupClick,couponBubbleHover} from '../track';
import CountDown from './countdown.vue';
import { loadImg } from '~/components/homepage/utils';
import { BasicUtils } from '~/utilities/basic/utils';

export default {
  components: {
    CountDown,
    LibButton,
    LibMask
  },
  props: {
    couponList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    // 组件配置数据
    comData: {
      type: Object,
      default: null
    },
    moduleCouponList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    showCard: {
      type: Boolean,
      default: false
    },
    commonConfig: {
      type: Object,
      default: null
    },
    successFlag: {
      type: Boolean,
      default: false
    },
    promoLoadTime: {
      type: Number,
      default: 3
    },
    animationNameProps: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      className: '',
      countdown: null,
      // 是否显示弹窗
      showPop: false,
      // 是否显示pc端组件
      modulePcShow: false,
      // 弹窗组件展示次数
      viewTimes: 0,
      animationName:this.animationNameProps ||'',
      bgAnimationName:'',
      isIosSafari: false
    };
  },
  computed: {
    ...mapState('myAccount', [
      'isLogin',
      'isEmployeeLogin',
      'isNonmemberLogin'
    ]),
    ...mapState('homePage', ['closeCompatibilityTips']),
    ...mapState('searchHistory',['splpFlag']),
    mobileModuleStyle(){
      return {
        backgroundImage: `url(${this.commonConfig!== null?this.commonConfig.promoModuleMobileBgPath: ''})`,
        backgroundColor: this.comData.backgroundColor || '#CF4430'
      };
    },
    couponBackground(){
      return {
        backgroundColor: this.comData.backgroundColor || '#CF4430'
      };
    },
    pcModuleStyle(){
      return {
        backgroundImage: `url(${this.commonConfig!== null?this.commonConfig.promoModulePcBgPath: ''})`,
        backgroundColor: this.comData.backgroundColor || '#CF4430'
      };
    },
    handImgUrl(){
      return this.commonConfig.promoHandPath;
    },
    couponDisabled(){
      return this.moduleCouponList.filter(item => item.stat);
    },
    list() {
      return this.moduleCouponList.slice(0, 3);
    },
    firstCoupon() {
      const item = this.moduleCouponList[0];
      return item;
    },
    duration() {
      const list = this.moduleCouponList.filter((item, index) => {
        item.endTime = item.endTime.replace(/-/g,"/");
        return new Date(item.endTime).getTime() - new Date().getTime() < 172800000;
      });
      list.sort((a, b) => {
        return new Date(a.endTime).getTime() - new Date(b.endTime).getTime();
      });
      console.log('list', list);
      const item = list[0];
      console.log('item', item);
      if (item) {
        return Math.round((new Date(item.endTime).getTime() - new Date().getTime())/1000);
      } else {
        return 0;
      }
    },
    popupDuration(){
      return item=>{
        return Math.round((new Date(item.endTime).getTime() - new Date().getTime())/1000);
      };
    },
    show() {
      return (this.viewTimes < 2) && this.showCard && this.showPop && this.commonConfig.enable && this.closeCompatibilityTips;
    },
    moduleShow() {
      return this.showCard;
    },
    animateStyle() {
      return `animation-delay: ${this.promoLoadTime}s;`;
    },
    popupCardStyle() {
      return {
        backgroundImage: `url(${this.commonConfig?.promoPopUpBgPath})`
      };
    },
    popupCardContainerStyle(){
      let style = {
        // backgroundImage: `url(${this.commonConfig?.promoPopUpBgPath})`
        backgroundColor: this.comData.backgroundColor || '#CF4430'
        // animationDelay: `${this.promoLoadTime}s`
      };
      // 这里在safari浏览器需要把位置偏上一点，其他浏览器正常
      if (window.innerWidth < 720 && this.isIosSafari) {
        style = {
          ...style,
          top: 'calc(50% - 20px)'
        };
      }
      return style;
    },
    popupCardContentStyle() {
      return {
        // backgroundColor: '#CF4430',
      };
    }
  },
  filters: {
    discountFilter: function(value) {
      const y = String(value).indexOf(".") + 1;
      if (y > 0) {
        return value*10;
      } else {
        return value;
      }
    }
  },
  watch: {
    show(val){
      // 如果展示了弹窗，则记录，后续不再打开
      if (val) {
        let viewTimes = sessionStorage.getItem("homepagePromoShowedFlag");
        viewTimes = viewTimes? Number(viewTimes) + 1:1;
        console.log("viewTimes",viewTimes);
        sessionStorage.setItem("homepagePromoShowedFlag",viewTimes);
        this.viewTimes = viewTimes;
        if ( viewTimes === 1 ) {
          const codeList = this.couponList.map(item=>item.promoCodeId).join("|");
          const number = this.couponList.length;
          couponPopupView(codeList, number);
          this.viewCouponTrack(true);
        }
      }
    }
  },
  async mounted() {
    const utils = new BasicUtils(this);
    this.isIosSafari = utils.isIosSafari;
    this.modulePcShow = this.show || this.showCard;
    const delayTime = this.commonConfig.popupWaitingTime * 1000;
    setTimeout(()=>{
      this.showPop = this.couponList.length > 0;
    },delayTime);
    try {
      // pc端由于优惠券模块的小手图片是配置的，所以这里需要图片加载完成时才显示动画
      if (window.innerWidth >= 720) {
        await loadImg(this.handImgUrl).then(() => {
          this.initHandAnimation();
        });
      } else {
        // mobilr端直接走其他逻辑，不用等待
        this.initHandAnimation();
      }
    } catch (err) {
      // 如果图片加载失败则直接显示
      this.initHandAnimation();
    }

  },
  methods: {
    trackCouponPopupClick(name){
      const codeList = this.couponList.map(item=>item.promoCodeId).join("|");
      const number = this.couponList.length;
      couponPopupClick(codeList, number,name);
    },
    initHandAnimation(){
      const delayTime = this.commonConfig.popupWaitingTime * 1000;
      if (!((this.viewTimes < 2) && this.showCard && this.couponList.length > 0 && this.commonConfig.enable)) {
        // 如果不展示弹窗,则直接显示小手动画
        this.className = 'hand absolute hand-init';
        setTimeout(()=>{
          this.className = 'hand absolute';
        },600);
      }
      setTimeout(()=>{
        // 显示优惠券时，提交埋点
        if (this.showCard && window.innerWidth < 720) {
          this.viewCouponTrack();
        }
        // 这里因为小手和弹窗要一起出现
        if(!this.className) {
          this.className = 'hand absolute hand-init';
          setTimeout(()=>{
            this.className = 'hand absolute';
          },600);
        }
      }, delayTime);
    },
    handleClosePopup(refuseFlag,popupFlag,moduleFlag){
      const pcRefuseFlag = window.innerWidth >= 720 && refuseFlag;
      // pc端且是为领券则需要缩放动画
      if (pcRefuseFlag) {
        this.closePopupByScale();
      } else {
        this.animationName = "leave-to";
        this.bgAnimationName = "bg-leave-to";
      }
      setTimeout(()=>{
        this.showPop = false;
      },pcRefuseFlag? 500:300);
      !moduleFlag && this.trackCouponPopupClick(popupFlag?"残忍拒绝":"blank");
    },
    stopClose(){
      // 这里仅仅为了停止冒泡，让点击弹框里不可关闭弹窗
    },
    // 优惠券弹窗缩放消失动画
    closePopupByScale(){
      const card = this.$refs.pcPromoCard;
      const popup = this.$refs.promoPopup;
      const popupContainer = this.$refs.promoPopupCon;
      const cardBound = card.getBoundingClientRect();
      const popupBound = popup.getBoundingClientRect();
      // 中心点的水平位移
      const offsetX = document.body.clientWidth - (popupBound.left) - popupBound.width / 2;
      // 这里偏移量为可视窗口高度一半及中心点距离底部距离减去小手和券 底部64距离 减去小手和券的一版高度 再减去喇叭的偏移量
      const offsetY = document.body.clientHeight / 2 - 64 - cardBound.height / 2 - popupBound.height / 2 + 38;
      popup.style.transition = "all .5s";
      popup.style.transitionTimingFunction = "cubic-bezier(.96,.16,.99,.97)";
      popup.style.transform = 'translateY('+ offsetY +'px) scale(0)';
      popupContainer.style.transition = "all .5s";
      popupContainer.style.transitionTimingFunction = "cubic-bezier(.45,.34,1,.8)";
      popupContainer.style.transform = 'translateX('+ offsetX +'px)';
      // popupContainer.style.right = '0';
    },
    couponBubbleHoverTrack(){
      const codeList = this.list.map(item=>item.promoCodeId).join("|");
      const number = this.list.length;
      couponBubbleHover(codeList, number);
      this.viewCouponTrack(false);
    },
    viewCouponTrack(popupFlag) {
      // const myAccountTrack = require('~/pages/index/track/index.ts');
      const couponAmount = [];
      const couponId = [];
      const couponName = [];
      const countDownList = [];
      const couponList = popupFlag? this.couponList:this.list;
      couponList.forEach((item) => {
        couponAmount.push(item.discount || item.reduction);
        couponId.push(item.promoCodeId);
        couponName.push(item.promoCodeName);
      });
      let canGetInFourtyEightHours = false;
      const today = DayJs(new Date());
      couponList.forEach((item) => {
        const { days } = LibFormatter.calcInterval(DayJs(item.endTime).toDate().getTime(), today.toDate().getTime());
        const itemCanGetInFourtyEightHours = days<2 && DayJs(item.startTime) < today;
        canGetInFourtyEightHours = itemCanGetInFourtyEightHours || canGetInFourtyEightHours;
        if (itemCanGetInFourtyEightHours) {
          const ItemCountDown = LibFormatter.countdown(item.endTime);
          countDownList.push(ItemCountDown);
        }
      });
      const obj = {
        couponamount: `[${couponAmount.toString()}]`,
        couponid: `[${couponId.toString()}]`,
        couponname: `[${couponName.toString()}]`,
        coupontype: '优惠码',
        pageSource: popupFlag? "HompagePopup":"HomepageModule",
        isHasCountdownCoupon: canGetInFourtyEightHours,
        countdownTimeList: `[${countDownList.toString()}]`
      };
      if (this.splpFlag) {
        obj.searchKeyword = this.splpFlag;
        this.$store.commit('searchHistory/setSplpFlag', "");
      }
      // console.log("埋点couponList",couponList,obj);
      try {
        myAccountTrack.CouponView(obj);
      } catch (e) {
        console.warn('trackCouponView', e);
      }
    },
    handleClick(popupFlag,moduleFlag=false) {
      clearInterval(this.countdown);
      if (this.isNonmemberLogin) {
        // 非会员登陆，引导入会
        this.$emit('loginMember',popupFlag);
      } else if (this.isLogin) {
        // 已经登陆直接领券
        this.$emit('receiveCoupon',popupFlag);
      } else {
        // 未登录，请登录，弹出没有非会员入口的登陆半窗
        this.$emit('loginMember',popupFlag);
      }
      this.handleClosePopup(false,popupFlag,moduleFlag);
      popupFlag && this.trackCouponPopupClick("立即领取");
    }
  }
};
